import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { SelectedChannelState } from './selected-channel.state';
import { shareReplay, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { PostResponse, PostsCrudService, PostsListResponse } from '../../api';
import { Injectable } from '@angular/core';
import PostState = PostResponse.StateEnum;

export class LoadPosts {
  static readonly type = '[PostsListState] LoadPosts';
}

export class LoadPostsSuccess {
  static readonly type = '[PostsListState] LoadPostsSuccess';

  constructor(public readonly payload: PostsListResponse) {
  }
}

export class LoadMorePosts {
  static readonly type = '[PostsListState] LoadMorePosts';
}

export class LoadNextPage {
  static readonly type = '[PostsListState] LoadNextPage';
}

export class LoadNextPageSuccess {
  static readonly type = '[PostsListState] LoadNextPageSuccess';

  constructor(public readonly skip: number, public readonly payload: PostsListResponse) {
  }
}

export class LoadPrevPage {
  static readonly type = '[PostsListState] LoadPrevPage';
}

export class LoadPrevPageSuccess {
  static readonly type = '[PostsListState] LoadPrevPageSuccess';

  constructor(public readonly skip: number, public readonly payload: PostsListResponse) {
  }
}

export class MoreLoadPostSuccess {
  static readonly type = '[PostsListState] MoreLoadPostSuccess';

  constructor(public readonly payload: PostsListResponse) {
  }
}

export class QueuePost {
  static readonly type = '[PostsListState] QueuePost';

  constructor(public readonly payload: { publish: { scheduleAt: string }; postId: string }) {
  }
}

export class QueuePostSuccess {
  static readonly type = '[PostsListState] QueuePostSuccess';

  constructor(public readonly payload: string, public readonly updated: PostResponse) {
  }
}

export class RemovePost {
  static readonly type = '[PostsListState] RemovePost';

  constructor(public readonly payload: { tgDelete: string; postId: string }) {
  }
}

export class RemovePostSuccess {
  static readonly type = '[PostsListState] RemovePostSuccess';

  constructor(public readonly payload: string) {
  }
}

export class RemovePostFromQueue {
  static readonly type = '[PostsListState] RemovePostFromQueue';

  constructor(public readonly payload: string) {
  }
}

export class RemovePostFromQueueSuccess {
  static readonly type = '[PostsListState] RemovePostFromQueueSuccess';

  constructor(public readonly payload: string) {
  }
}

export class RemovePostFromTelegram {
  static readonly type = '[PostsListState] RemovePostFromTelegram';

  constructor(public readonly payload: string) {
  }
}

export class RemovePostFromTelegramSuccess {
  static readonly type = '[PostsListState] RemovePostFromTelegramSuccess';

  constructor(public readonly payload: string) {
  }
}

export class PreviewPost {
  static readonly type = '[PostsListState] PreviewPost';

  constructor(public readonly payload: string) {
  }
}

export class PreviewPostSuccess {
  static readonly type = '[PostsListState] PreviewPostSuccess';

  constructor(public readonly payload: string) {
  }
}

export class SendPostNow {
  static readonly type = '[PostsListState] SendPostNow';

  constructor(public readonly payload: string) {
  }
}

export class SendPostNowSuccess {
  static readonly type = '[PostsListState] SendPostNowSuccess';

  constructor(public readonly payload: string) {
  }
}

export class ActionPostFail {
  static readonly type = '[PostsListState] ActionPostFail';

  constructor(public readonly payload: { msg: HttpErrorResponse; title: string }) {
  }
}

export class SetQuery {
  static readonly type = '[LoadPostsQueryState] SetQuery';

  constructor(public readonly payload: LoadPostsQueryModel) {
  }
}

export class PatchQuery {
  static readonly type = '[LoadPostsQueryState] PatchQuery';

  constructor(public readonly payload: LoadPostsQueryModel) {
  }
}

export class RestorePost {
  static readonly type = '[PostsListState] RestorePost';

  constructor(public readonly payload: { tgDelete: string; postId: string }) {
  }
}

export class SetSearchQuery {
  static readonly type = '[PostsListState] SetSearchQuery';

  constructor(public readonly payload: string) {
  }
}

export class PatchSearchQuery {
  static readonly type = '[PostsListState] PatchSearchQuery';

  constructor(public readonly payload: string) {
  }
}

export interface LoadPostsQueryModel {
  state?: PostState;
  skip?: number;
  take?: number;
  order?: string;
  where?: object;
  __search?: string;
}

export interface PostsListStateModel {
  count: number;
  items: PostResponse[];
  loaded: boolean;
  loading: boolean;
  query?: LoadPostsQueryModel;
}

@State<LoadPostsQueryModel>({
  name: 'query',
  defaults: {
    state: PostState.Draft,
    skip: 0,
    take: 20,
    order: '-createdAt',
  },
})
@Injectable()
export class LoadPostsQueryState {
  @Selector()
  static getQuery(query: LoadPostsQueryModel) {
    console.log('QUERY', query);
    return query;
  }

  @Action(PatchQuery)
  patchQuery({ getState, setState }: StateContext<LoadPostsQueryModel>, { payload }: PatchQuery) {
    setState({
      ...getState(),
      ...payload,
    });
  }

  @Action(SetQuery)
  setQuery({ getState, setState }: StateContext<LoadPostsQueryModel>, { payload }: SetQuery) {
    setState(payload);
  }

  @Action(SetSearchQuery)
  setSearchQuery({ getState, setState }: StateContext<LoadPostsQueryModel>, { payload }: SetSearchQuery) {
    let oldState = getState();
    setState({
      ...oldState,
      __search: payload,
    });
  }
}

@State<PostsListStateModel>({
  name: 'channelPosts',
  defaults: {
    count: 0,
    items: [],
    loaded: false,
    loading: false,
  },
  children: [LoadPostsQueryState],
})
@Injectable()
export class PostsListState {
  constructor(private store: Store, private api: PostsCrudService) {
  }

  @Action(LoadPosts)
  loadPosts({ getState, setState, dispatch }: StateContext<PostsListStateModel>) {
    const state = getState();
    setState({
      ...getState(),
      loading: true,
    });

    const channelId = this.store.selectSnapshot(SelectedChannelState.id);
    if (!channelId) {
      console.error('No selected channel');
      return;
    }

    return this.api
      .list(
        [channelId],
        state.query.state as any,
        state.query.skip,
        state.query.take,
        state.query.order,
        JSON.stringify(state.query.where),
        state.query.__search,
      )
      .pipe(tap(posts => dispatch(new LoadPostsSuccess(posts))));
  }

  @Action(LoadPostsSuccess)
  postsListSuccess({ getState, setState }: StateContext<PostsListStateModel>, { payload }: LoadPostsSuccess) {
    setState({
      ...getState(),
      ...payload,
      loaded: true,
      loading: false,
    });
  }

  loadPage(ctx: StateContext<PostsListStateModel>, skip: number, successDispatch: { new(skip: number, posts: PostsListResponse): any }) {
    let { getState, setState, dispatch } = ctx;
    const state = getState();
    setState({
      ...getState(),
      loading: true,
    });

    const channelId = this.store.selectSnapshot(SelectedChannelState.id);

    return this.api
      .list(
        [channelId],
        state.query.state as any,
        skip,
        state.query.take,
        state.query.order,
        JSON.stringify(state.query.where),
      )
      .pipe(
        tap(posts => dispatch(new successDispatch(skip, posts))),
        shareReplay(),
      );
  }

  @Action(LoadNextPage)
  moreNextPage(context: StateContext<PostsListStateModel>) {
    const state = context.getState();
    return this.loadPage(context, state.query.skip + state.query.take, LoadNextPageSuccess);
  }

  @Action(LoadPrevPage)
  morePrevPage(context: StateContext<PostsListStateModel>) {
    const state = context.getState();
    return this.loadPage(context, state.query.skip - state.query.take, LoadPrevPageSuccess);
  }

  @Action([LoadNextPageSuccess, LoadPrevPageSuccess])
  moreNextPageSuccess({ getState, setState }: StateContext<PostsListStateModel>, { payload, skip }: LoadNextPageSuccess) {
    const state = getState();
    setState({
      ...getState(),
      count: payload.count,
      items: payload.items,
      loaded: true,
      loading: false,
      query: {
        ...state.query,
        skip,
      },
    });
  }

  @Action(LoadMorePosts)
  moreLoadPost({ getState, setState, dispatch }: StateContext<PostsListStateModel>) {
    const state = getState();
    setState({
      ...getState(),
      loading: true,
    });

    const channelId = this.store.selectSnapshot(SelectedChannelState.id);

    return this.api
      .list(
        [channelId],
        state.query.state as any,
        state.query.skip + state.query.take,
        state.query.take,
        state.query.order,
        JSON.stringify(state.query.where),
      )
      .pipe(
        tap(posts => dispatch(new MoreLoadPostSuccess(posts))),
        shareReplay(),
      );
  }

  @Action(MoreLoadPostSuccess)
  moreLoadPostSuccess({ getState, setState }: StateContext<PostsListStateModel>, { payload }: MoreLoadPostSuccess) {
    const state = getState();
    setState({
      ...getState(),
      items: state.items.concat(payload.items),
      loaded: true,
      loading: false,
      query: {
        ...state.query,
        skip: state.query.skip + state.query.take,
      },
    });
  }

  @Action(QueuePost)
  queuePost({ getState, setState, dispatch }: StateContext<PostsListStateModel>, { payload }: QueuePost) {
    setState({ ...getState(), loading: true });

    return this.api.toQueue(payload.publish, payload.postId).pipe(
      tap(
        (post) =>
          dispatch(
            new QueuePostSuccess(
              $localize`:Post list state|notify @@postListState.postQueueSuccess:Пост добавлен в очередь`,
              post,
            ),
          ),
        error =>
          dispatch(
            new ActionPostFail({
              msg: error,
              title: $localize`:Post list state|notify @@postListState.postQueueFail:Невозможно поставить в очередь пост`,
            }),
          ),
      ),
      shareReplay(),
    );
  }

  @Action(QueuePostSuccess)
  queuePostSuccess({ getState, setState }: StateContext<PostsListStateModel>, { payload, updated }: QueuePostSuccess) {
    let state = getState();
    let itemIndex = state.items.findIndex(p => p.id === updated.id);
    if (itemIndex !== -1) {
      state.items[itemIndex] = updated;
    }
    setState({
      ...state,
      loaded: true,
      loading: false,
    });
  }

  @Action(RemovePost)
  removePost({ getState, setState, dispatch }: StateContext<PostsListStateModel>, { payload }: RemovePost) {
    setState({ ...getState(), loading: true });

    return this.api.remove(payload.tgDelete, payload.postId).pipe(
      tap(
        () =>
          dispatch(
            new RemovePostSuccess($localize`:Post list state|notify @@postListState.postDeleteSuccess:Пост удален`),
          ),
        error =>
          dispatch(
            new ActionPostFail({
              msg: error,
              title: $localize`:Post list state|notify @@postListState.postDeleteFail:Невозможно удалить пост`,
            }),
          ),
      ),
      shareReplay(),
    );
  }

  @Action(RemovePostSuccess)
  removePostSuccess({ getState, setState }: StateContext<PostsListStateModel>, { payload }: RemovePostSuccess) {
    setState({
      ...getState(),
      loaded: true,
      loading: false,
    });
  }

  @Action(RemovePostFromQueue)
  removePostFromQueue(
    { getState, setState, dispatch }: StateContext<PostsListStateModel>,
    { payload }: RemovePostFromQueue,
  ) {
    setState({ ...getState(), loading: true });

    return this.api.deQueue(payload).pipe(
      tap(
        () =>
          dispatch(
            new RemovePostFromQueueSuccess(
              $localize`:Post list state|notify @@postListState.postDeQueueSuccess:Пост снят с очереди`,
            ),
          ),
        error =>
          dispatch(
            new ActionPostFail({
              msg: error,
              title: $localize`:Post list state|notify @@postListState.postDeQueueFail:Невозможно снять пост с очереди`,
            }),
          ),
      ),
      shareReplay(),
    );
  }

  @Action(RemovePostFromQueueSuccess)
  removePostFromQueueSuccess(
    { getState, setState }: StateContext<PostsListStateModel>,
    { payload }: RemovePostFromQueueSuccess,
  ) {
    setState({
      ...getState(),
      loaded: true,
      loading: false,
    });
  }

  @Action(RemovePostFromTelegram)
  removePostFromTelegram(
    { getState, setState, dispatch }: StateContext<PostsListStateModel>,
    { payload }: RemovePostFromTelegram,
  ) {
    setState({ ...getState(), loading: true });

    const channelId = this.store.selectSnapshot(SelectedChannelState.id);

    return this.api.removePostFromTelegram(payload).pipe(
      tap(
        () =>
          dispatch(
            new RemovePostFromTelegramSuccess(
              $localize`:Post list state|notify @@postListState.postDeleteTelegramSuccess:Пост удален из Telegram`,
            ),
          ),
        error =>
          dispatch(
            new ActionPostFail({
              msg: error,
              title: $localize`:Post list state|notify @@postListState.postDeleteTelegramFail:Невозможно удалить пост из Telegram`,
            }),
          ),
      ),
      shareReplay(),
    );
  }

  @Action(RemovePostFromTelegramSuccess)
  removePostFromTelegramSuccess(
    { getState, setState }: StateContext<PostsListStateModel>,
    { payload }: RemovePostFromTelegramSuccess,
  ) {
    setState({
      ...getState(),
      loaded: true,
      loading: false,
    });
  }

  @Action(PreviewPost)
  previewPost({ getState, setState, dispatch }: StateContext<PostsListStateModel>, { payload }: PreviewPost) {
    setState({ ...getState(), loading: true });
    return this.api.preview(payload).pipe(
      tap(
        () =>
          dispatch(
            new PreviewPostSuccess(
              $localize`:Post list state|notify @@postListState.postPreviewSuccess:Пост отправлен вам в бот`,
            ),
          ),
        error =>
          dispatch(
            new ActionPostFail({
              msg: error,
              title: $localize`:Post list state|notify @@postListState.postPreviewFail:Невозможно отправить пост`,
            }),
          ),
      ),
    );
  }

  @Action(SendPostNow)
  sendPostNow({ getState, setState, dispatch }: StateContext<PostsListStateModel>, { payload }: SendPostNow) {
    setState({ ...getState(), loading: true });

    return this.api.publish(payload).pipe(
      tap(
        () =>
          dispatch(
            new SendPostNowSuccess($localize`:Post list state|notify @@postListState.postSendSuccess:Пост опубликован`),
          ),
        error =>
          dispatch(
            new ActionPostFail({
              msg: error,
              title: $localize`:Post list state|notify @@postListState.postSendFail:Невозможно опубликовать пост`,
            }),
          ),
      ),
    );
  }

  @Action(SendPostNowSuccess)
  sendPostNowSuccess({ getState, setState }: StateContext<PostsListStateModel>, { payload }: SendPostNowSuccess) {
    setState({
      ...getState(),
      loaded: true,
      loading: false,
    });
  }

  @Action(ActionPostFail)
  sendPostNowFail({ getState, setState }: StateContext<PostsListStateModel>, { payload }: ActionPostFail) {
    setState({
      ...getState(),
      loaded: true,
      loading: false,
    });
  }

  @Action(RestorePost)
  restorePost({ getState, setState, dispatch }: StateContext<PostsListStateModel>, { payload }: RestorePost) {
    setState({ ...getState(), loading: true });

    return this.api.restore(payload.postId).pipe(
      tap(
        () =>
          dispatch(
            new RemovePostSuccess(
              $localize`:Post list state|notify @@postListState.postRestoreSuccess:Пост восстановлен`,
            ),
          ),
        error =>
          dispatch(
            new ActionPostFail({
              msg: error,
              title: $localize`:Post list state|notify @@postListState.postRestoreFail:Невозможно восстановить пост`,
            }),
          ),
      ),
      shareReplay(),
    );
  }
}
